import React, { useEffect, useState } from 'react';
import '../components/css/mission.css';
import profileImage from "./media/profile.png";
import coinImage from "./media/coin.png";
import bronzeMedalImage from './media/bronze.png';
interface MissionsProps {

};

const MissionsPage: React.FC<MissionsProps> = () => {



    return (
        <>
            <div className="box1">
                <div className="userData">
                    <img src={profileImage} alt="" className="profile" />
                    <div className="user-data">
                        <p className="user-name">Emily Middleton</p>
                        <p className="user-id">UID: 452815486</p>
                    </div>
                </div>

                <div className="wallet">
                    <div className="plus">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect width="24" height="24" rx="10" fill="black" />
                            <path d="M18 10H14V6C14 5.46957 13.7893 4.96086 13.4142 4.58579C13.0391 4.21071 12.5304 4 12 4C11.4696 4 10.9609 4.21071 10.5858 4.58579C10.2107 4.96086 10 5.46957 10 6L10.071 10H6C5.46957 10 4.96086 10.2107 4.58579 10.5858C4.21071 10.9609 4 11.4696 4 12C4 12.5304 4.21071 13.0391 4.58579 13.4142C4.96086 13.7893 5.46957 14 6 14L10.071 13.929L10 18C10 18.5304 10.2107 19.0391 10.5858 19.4142C10.9609 19.7893 11.4696 20 12 20C12.5304 20 13.0391 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18V13.929L18 14C18.5304 14 19.0391 13.7893 19.4142 13.4142C19.7893 13.0391 20 12.5304 20 12C20 11.4696 19.7893 10.9609 19.4142 10.5858C19.0391 10.2107 18.5304 10 18 10Z" fill="#F7F7F7" />
                        </svg>
                    </div>
                    <button className="wallet"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <path d="M24.5 21V22.1667C24.5 22.7855 24.2542 23.379 23.8166 23.8166C23.379 24.2542 22.7855 24.5 22.1667 24.5H5.83333C5.21449 24.5 4.621 24.2542 4.18342 23.8166C3.74583 23.379 3.5 22.7855 3.5 22.1667V5.83333C3.5 5.21449 3.74583 4.621 4.18342 4.18342C4.621 3.74583 5.21449 3.5 5.83333 3.5H22.1667C22.7855 3.5 23.379 3.74583 23.8166 4.18342C24.2542 4.621 24.5 5.21449 24.5 5.83333V7H14C13.3812 7 12.7877 7.24583 12.3501 7.68342C11.9125 8.121 11.6667 8.71449 11.6667 9.33333V18.6667C11.6667 19.2855 11.9125 19.879 12.3501 20.3166C12.7877 20.7542 13.3812 21 14 21M14 18.6667H25.6667V9.33333H14M18.6667 15.75C18.2025 15.75 17.7574 15.5656 17.4292 15.2374C17.101 14.9092 16.9167 14.4641 16.9167 14C16.9167 13.5359 17.101 13.0908 17.4292 12.7626C17.7574 12.4344 18.2025 12.25 18.6667 12.25C19.1308 12.25 19.5759 12.4344 19.9041 12.7626C20.2323 13.0908 20.4167 13.5359 20.4167 14C20.4167 14.4641 20.2323 14.9092 19.9041 15.2374C19.5759 15.5656 19.1308 15.75 18.6667 15.75Z" fill="#F7F7F7" />
                    </svg></button>
                </div>
            </div>

            <div className="box2-mask">

            </div>
            <div className="box2">
                <div className="mission-title">
                    <p className="t1">
                        Mission  Center
                    </p>
                    <p className="t2">
                        Ready to Earn More? Dive into tasks designed to help you grow your earnings. From bonuses to daily challenges, your next opportunity is just a click away.
                    </p>
                </div>

                <div className="daily-task">
                    <p className="title">
                        Daily Tasks
                    </p>

                    <div className="task">
                        <img src="" alt="" />
                        <div className="data">
                            <div className="t1">
                                Verify Phone Number
                            </div>
                            <div className="coin">
                                <img src={coinImage} alt="" />
                                <p>
                                    1.00

                                </p>
                            </div>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4133 15.0866C21.7879 15.4616 21.9982 15.9699 21.9982 16.4999C21.9982 17.0299 21.7879 17.5382 21.4133 17.9132L13.872 25.4572C13.4968 25.8322 12.988 26.0428 12.4575 26.0427C11.927 26.0426 11.4183 25.8317 11.0433 25.4566C10.6683 25.0814 10.4577 24.5726 10.4578 24.0421C10.4579 23.5116 10.6688 23.0029 11.044 22.6279L17.172 16.4999L11.044 10.3719C10.6795 9.99485 10.4777 9.48974 10.482 8.96535C10.4863 8.44095 10.6964 7.93922 11.067 7.56823C11.4377 7.19724 11.9392 6.98666 12.4636 6.98186C12.988 6.97706 13.4933 7.17841 13.8707 7.54255L21.4147 15.0852L21.4133 15.0866Z" fill="#CDCDCD" />
                        </svg>
                    </div>
                </div>


                <div className="daily-task Quest">
                    <p className="title">
                        Quest
                    </p>

                    <div className="task">
                        <img src="" alt="" />
                        <div className="data">
                            <div className="t1">
                                Verify Phone Number
                            </div>
                            <div className="coin">
                                <img src={coinImage} alt="" />
                                <p>
                                    1.00

                                </p>
                            </div>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4133 15.0866C21.7879 15.4616 21.9982 15.9699 21.9982 16.4999C21.9982 17.0299 21.7879 17.5382 21.4133 17.9132L13.872 25.4572C13.4968 25.8322 12.988 26.0428 12.4575 26.0427C11.927 26.0426 11.4183 25.8317 11.0433 25.4566C10.6683 25.0814 10.4577 24.5726 10.4578 24.0421C10.4579 23.5116 10.6688 23.0029 11.044 22.6279L17.172 16.4999L11.044 10.3719C10.6795 9.99485 10.4777 9.48974 10.482 8.96535C10.4863 8.44095 10.6964 7.93922 11.067 7.56823C11.4377 7.19724 11.9392 6.98666 12.4636 6.98186C12.988 6.97706 13.4933 7.17841 13.8707 7.54255L21.4147 15.0852L21.4133 15.0866Z" fill="#CDCDCD" />
                        </svg>
                    </div>

                    <div className="task">
                        <img src="" alt="" />
                        <div className="data">
                            <div className="t1">
                                Verify Phone Number
                            </div>
                            <div className="coin">
                                <img src={coinImage} alt="" />
                                <p>
                                    1.00

                                </p>
                            </div>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4133 15.0866C21.7879 15.4616 21.9982 15.9699 21.9982 16.4999C21.9982 17.0299 21.7879 17.5382 21.4133 17.9132L13.872 25.4572C13.4968 25.8322 12.988 26.0428 12.4575 26.0427C11.927 26.0426 11.4183 25.8317 11.0433 25.4566C10.6683 25.0814 10.4577 24.5726 10.4578 24.0421C10.4579 23.5116 10.6688 23.0029 11.044 22.6279L17.172 16.4999L11.044 10.3719C10.6795 9.99485 10.4777 9.48974 10.482 8.96535C10.4863 8.44095 10.6964 7.93922 11.067 7.56823C11.4377 7.19724 11.9392 6.98666 12.4636 6.98186C12.988 6.97706 13.4933 7.17841 13.8707 7.54255L21.4147 15.0852L21.4133 15.0866Z" fill="#CDCDCD" />
                        </svg>
                    </div>

                    <div className="task">
                        <img src="" alt="" />
                        <div className="data">
                            <div className="t1">
                                Verify Phone Number
                            </div>
                            <div className="coin">
                                <img src={coinImage} alt="" />
                                <p>
                                    1.00

                                </p>
                            </div>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4133 15.0866C21.7879 15.4616 21.9982 15.9699 21.9982 16.4999C21.9982 17.0299 21.7879 17.5382 21.4133 17.9132L13.872 25.4572C13.4968 25.8322 12.988 26.0428 12.4575 26.0427C11.927 26.0426 11.4183 25.8317 11.0433 25.4566C10.6683 25.0814 10.4577 24.5726 10.4578 24.0421C10.4579 23.5116 10.6688 23.0029 11.044 22.6279L17.172 16.4999L11.044 10.3719C10.6795 9.99485 10.4777 9.48974 10.482 8.96535C10.4863 8.44095 10.6964 7.93922 11.067 7.56823C11.4377 7.19724 11.9392 6.98666 12.4636 6.98186C12.988 6.97706 13.4933 7.17841 13.8707 7.54255L21.4147 15.0852L21.4133 15.0866Z" fill="#CDCDCD" />
                        </svg>
                    </div>



                    <div className="task">
                        <img src="" alt="" />
                        <div className="data">
                            <div className="t1">
                                Verify Phone Number
                            </div>
                            <div className="coin">
                                <img src={coinImage} alt="" />
                                <p>
                                    1.00

                                </p>
                            </div>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4133 15.0866C21.7879 15.4616 21.9982 15.9699 21.9982 16.4999C21.9982 17.0299 21.7879 17.5382 21.4133 17.9132L13.872 25.4572C13.4968 25.8322 12.988 26.0428 12.4575 26.0427C11.927 26.0426 11.4183 25.8317 11.0433 25.4566C10.6683 25.0814 10.4577 24.5726 10.4578 24.0421C10.4579 23.5116 10.6688 23.0029 11.044 22.6279L17.172 16.4999L11.044 10.3719C10.6795 9.99485 10.4777 9.48974 10.482 8.96535C10.4863 8.44095 10.6964 7.93922 11.067 7.56823C11.4377 7.19724 11.9392 6.98666 12.4636 6.98186C12.988 6.97706 13.4933 7.17841 13.8707 7.54255L21.4147 15.0852L21.4133 15.0866Z" fill="#CDCDCD" />
                        </svg>
                    </div>



                    <div className="task">
                        <img src="" alt="" />
                        <div className="data">
                            <div className="t1">
                                Verify Phone Number
                            </div>
                            <div className="coin">
                                <img src={coinImage} alt="" />
                                <p>
                                    1.00

                                </p>
                            </div>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4133 15.0866C21.7879 15.4616 21.9982 15.9699 21.9982 16.4999C21.9982 17.0299 21.7879 17.5382 21.4133 17.9132L13.872 25.4572C13.4968 25.8322 12.988 26.0428 12.4575 26.0427C11.927 26.0426 11.4183 25.8317 11.0433 25.4566C10.6683 25.0814 10.4577 24.5726 10.4578 24.0421C10.4579 23.5116 10.6688 23.0029 11.044 22.6279L17.172 16.4999L11.044 10.3719C10.6795 9.99485 10.4777 9.48974 10.482 8.96535C10.4863 8.44095 10.6964 7.93922 11.067 7.56823C11.4377 7.19724 11.9392 6.98666 12.4636 6.98186C12.988 6.97706 13.4933 7.17841 13.8707 7.54255L21.4147 15.0852L21.4133 15.0866Z" fill="#CDCDCD" />
                        </svg>
                    </div>



                    <div className="task">
                        <img src="" alt="" />
                        <div className="data">
                            <div className="t1">
                                Verify Phone Number
                            </div>
                            <div className="coin">
                                <img src={coinImage} alt="" />
                                <p>
                                    1.00

                                </p>
                            </div>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4133 15.0866C21.7879 15.4616 21.9982 15.9699 21.9982 16.4999C21.9982 17.0299 21.7879 17.5382 21.4133 17.9132L13.872 25.4572C13.4968 25.8322 12.988 26.0428 12.4575 26.0427C11.927 26.0426 11.4183 25.8317 11.0433 25.4566C10.6683 25.0814 10.4577 24.5726 10.4578 24.0421C10.4579 23.5116 10.6688 23.0029 11.044 22.6279L17.172 16.4999L11.044 10.3719C10.6795 9.99485 10.4777 9.48974 10.482 8.96535C10.4863 8.44095 10.6964 7.93922 11.067 7.56823C11.4377 7.19724 11.9392 6.98666 12.4636 6.98186C12.988 6.97706 13.4933 7.17841 13.8707 7.54255L21.4147 15.0852L21.4133 15.0866Z" fill="#CDCDCD" />
                        </svg>
                    </div>



                    <div className="task">
                        <img src="" alt="" />
                        <div className="data">
                            <div className="t1">
                                Verify Phone Number
                            </div>
                            <div className="coin">
                                <img src={coinImage} alt="" />
                                <p>
                                    1.00

                                </p>
                            </div>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4133 15.0866C21.7879 15.4616 21.9982 15.9699 21.9982 16.4999C21.9982 17.0299 21.7879 17.5382 21.4133 17.9132L13.872 25.4572C13.4968 25.8322 12.988 26.0428 12.4575 26.0427C11.927 26.0426 11.4183 25.8317 11.0433 25.4566C10.6683 25.0814 10.4577 24.5726 10.4578 24.0421C10.4579 23.5116 10.6688 23.0029 11.044 22.6279L17.172 16.4999L11.044 10.3719C10.6795 9.99485 10.4777 9.48974 10.482 8.96535C10.4863 8.44095 10.6964 7.93922 11.067 7.56823C11.4377 7.19724 11.9392 6.98666 12.4636 6.98186C12.988 6.97706 13.4933 7.17841 13.8707 7.54255L21.4147 15.0852L21.4133 15.0866Z" fill="#CDCDCD" />
                        </svg>
                    </div>



                    <div className="task">
                        <img src="" alt="" />
                        <div className="data">
                            <div className="t1">
                                Verify Phone Number
                            </div>
                            <div className="coin">
                                <img src={coinImage} alt="" />
                                <p>
                                    1.00

                                </p>
                            </div>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4133 15.0866C21.7879 15.4616 21.9982 15.9699 21.9982 16.4999C21.9982 17.0299 21.7879 17.5382 21.4133 17.9132L13.872 25.4572C13.4968 25.8322 12.988 26.0428 12.4575 26.0427C11.927 26.0426 11.4183 25.8317 11.0433 25.4566C10.6683 25.0814 10.4577 24.5726 10.4578 24.0421C10.4579 23.5116 10.6688 23.0029 11.044 22.6279L17.172 16.4999L11.044 10.3719C10.6795 9.99485 10.4777 9.48974 10.482 8.96535C10.4863 8.44095 10.6964 7.93922 11.067 7.56823C11.4377 7.19724 11.9392 6.98666 12.4636 6.98186C12.988 6.97706 13.4933 7.17841 13.8707 7.54255L21.4147 15.0852L21.4133 15.0866Z" fill="#CDCDCD" />
                        </svg>
                    </div>



                    <div className="task">
                        <img src="" alt="" />
                        <div className="data">
                            <div className="t1">
                                Verify Phone Number
                            </div>
                            <div className="coin">
                                <img src={coinImage} alt="" />
                                <p>
                                    1.00

                                </p>
                            </div>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4133 15.0866C21.7879 15.4616 21.9982 15.9699 21.9982 16.4999C21.9982 17.0299 21.7879 17.5382 21.4133 17.9132L13.872 25.4572C13.4968 25.8322 12.988 26.0428 12.4575 26.0427C11.927 26.0426 11.4183 25.8317 11.0433 25.4566C10.6683 25.0814 10.4577 24.5726 10.4578 24.0421C10.4579 23.5116 10.6688 23.0029 11.044 22.6279L17.172 16.4999L11.044 10.3719C10.6795 9.99485 10.4777 9.48974 10.482 8.96535C10.4863 8.44095 10.6964 7.93922 11.067 7.56823C11.4377 7.19724 11.9392 6.98666 12.4636 6.98186C12.988 6.97706 13.4933 7.17841 13.8707 7.54255L21.4147 15.0852L21.4133 15.0866Z" fill="#CDCDCD" />
                        </svg>
                    </div>



                    <div className="task">
                        <img src="" alt="" />
                        <div className="data">
                            <div className="t1">
                                Verify Phone Number
                            </div>
                            <div className="coin">
                                <img src={coinImage} alt="" />
                                <p>
                                    1.00

                                </p>
                            </div>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4133 15.0866C21.7879 15.4616 21.9982 15.9699 21.9982 16.4999C21.9982 17.0299 21.7879 17.5382 21.4133 17.9132L13.872 25.4572C13.4968 25.8322 12.988 26.0428 12.4575 26.0427C11.927 26.0426 11.4183 25.8317 11.0433 25.4566C10.6683 25.0814 10.4577 24.5726 10.4578 24.0421C10.4579 23.5116 10.6688 23.0029 11.044 22.6279L17.172 16.4999L11.044 10.3719C10.6795 9.99485 10.4777 9.48974 10.482 8.96535C10.4863 8.44095 10.6964 7.93922 11.067 7.56823C11.4377 7.19724 11.9392 6.98666 12.4636 6.98186C12.988 6.97706 13.4933 7.17841 13.8707 7.54255L21.4147 15.0852L21.4133 15.0866Z" fill="#CDCDCD" />
                        </svg>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MissionsPage;
