import React, { useEffect, useState } from 'react';
import '../components/css/invite.css';
import coinImage from "./media/coin.png";
import giftBoxImage from './media/giftbox.png';
import giftBagImage from './media/giftBag.png';
interface InviteProps {

};

const InvitePage: React.FC<InviteProps> = () => {

    return (
        <>
            <div className="sec1">
                <div className="intro">
                    <p className="t1">Invite your Friends!</p>
                    <p className="t2">
                        invite your friends then you will get a bonus
                    </p>
                </div>

                <div className="bonus-types">
                    <div className="bonus">

                        <img src={giftBoxImage} alt="" />

                        <div className="data">
                            <p className="t1">Register Bonus</p>
                            <div className="coin">
                                <img src={coinImage} alt="" /> + 1.0 if your friend register
                            </div>
                        </div>

                        <button>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4133 14.5866C21.7879 14.9616 21.9982 15.4699 21.9982 15.9999C21.9982 16.5299 21.7879 17.0382 21.4133 17.4132L13.872 24.9572C13.4968 25.3322 12.988 25.5428 12.4575 25.5427C11.927 25.5426 11.4183 25.3317 11.0433 24.9566C10.6683 24.5814 10.4577 24.0726 10.4578 23.5421C10.4579 23.0116 10.6688 22.5029 11.044 22.1279L17.172 15.9999L11.044 9.87189C10.6795 9.49485 10.4777 8.98974 10.482 8.46535C10.4863 7.94095 10.6964 7.43922 11.067 7.06823C11.4377 6.69724 11.9392 6.48666 12.4636 6.48186C12.988 6.47706 13.4933 6.67841 13.8707 7.04255L21.4147 14.5852L21.4133 14.5866Z" fill="#CDCDCD" />
                            </svg>
                        </button>
                    </div>
                    <div className="bonus">

                        <img src={giftBagImage} alt="" />

                        <div className="data">
                            <p className="t1">Deposit Commission</p>
                            <div className="coin">
                                <img src={coinImage} alt="" /> + 0.5% if your friend deposit
                            </div>
                        </div>

                        <button>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4133 14.5866C21.7879 14.9616 21.9982 15.4699 21.9982 15.9999C21.9982 16.5299 21.7879 17.0382 21.4133 17.4132L13.872 24.9572C13.4968 25.3322 12.988 25.5428 12.4575 25.5427C11.927 25.5426 11.4183 25.3317 11.0433 24.9566C10.6683 24.5814 10.4577 24.0726 10.4578 23.5421C10.4579 23.0116 10.6688 22.5029 11.044 22.1279L17.172 15.9999L11.044 9.87189C10.6795 9.49485 10.4777 8.98974 10.482 8.46535C10.4863 7.94095 10.6964 7.43922 11.067 7.06823C11.4377 6.69724 11.9392 6.48666 12.4636 6.48186C12.988 6.47706 13.4933 6.67841 13.8707 7.04255L21.4147 14.5852L21.4133 14.5866Z" fill="#CDCDCD" />
                            </svg>
                        </button>
                    </div>


                    <div className="bonus">

                        <button className="invite-button">
                            Invite and Earn
                        </button>


                    </div>


                </div>

                <div className="invite-link">
                    <div className="title">
                        <p className="t1">
                            List your Friends!
                        </p>
                        <a href="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12ZM12.293 8.707C12.1108 8.5184 12.01 8.2658 12.0123 8.0036C12.0146 7.7414 12.1198 7.49059 12.3052 7.30518C12.4906 7.11977 12.7414 7.0146 13.0036 7.01233C13.2658 7.01005 13.5184 7.11084 13.707 7.293L15.707 9.293C15.8945 9.48053 15.9998 9.73484 15.9998 10C15.9998 10.2652 15.8945 10.5195 15.707 10.707L13.707 12.707C13.5184 12.8892 13.2658 12.99 13.0036 12.9877C12.7414 12.9854 12.4906 12.8802 12.3052 12.6948C12.1198 12.5094 12.0146 12.2586 12.0123 11.9964C12.01 11.7342 12.1108 11.4816 12.293 11.293L12.586 11H12C11.66 11 11.128 11.11 10.71 11.412C10.342 11.676 10 12.126 10 13C10 13.874 10.342 14.324 10.71 14.588C11.128 14.89 11.66 15 12 15C12.368 15 12.945 14.872 13.37 14.527C13.5761 14.3599 13.8401 14.2815 14.1039 14.3091C14.3678 14.3367 14.6099 14.4679 14.777 14.674C14.9441 14.8801 15.0225 15.1441 14.9949 15.4079C14.9673 15.6718 14.8361 15.9139 14.63 16.081C13.76 16.786 12.7 17 12 17C11.1191 16.9925 10.2613 16.7177 9.54 16.212C8.659 15.576 8 14.526 8 13C8 11.474 8.658 10.424 9.54 9.788C10.2613 9.28231 11.1191 9.00752 12 9H12.586L12.293 8.707Z" fill="#F7F7F7" />
                            </svg>
                        </a>
                    </div>

                    <div className="invite-button">
                        <button className="b">
                            Invite Friends
                        </button>
                        <button className="copy">

                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M6.14502 6.54H8.08502V4.69C8.08406 4.15983 8.23979 3.64121 8.53264 3.19926C8.82549 2.75731 9.24241 2.41176 9.73102 2.206C10.0557 2.07133 10.4035 2.00136 10.755 2H18.805C19.157 2.00131 19.5052 2.07193 19.8299 2.20782C20.1545 2.34372 20.4492 2.54223 20.6971 2.79202C20.9451 3.04181 21.1414 3.33799 21.2748 3.66364C21.4083 3.9893 21.4763 4.33806 21.475 4.69V14.78C21.4763 15.1319 21.4083 15.4807 21.2748 15.8064C21.1414 16.132 20.9451 16.4282 20.6971 16.678C20.4492 16.9278 20.1545 17.1263 19.8299 17.2622C19.5052 17.3981 19.157 17.4687 18.805 17.47H16.865V19.32C16.865 20.0291 16.584 20.7092 16.0836 21.2115C15.5832 21.7138 14.9041 21.9974 14.195 22H6.19502C5.48597 21.9974 4.80687 21.7138 4.30643 21.2115C3.806 20.7092 3.52501 20.0291 3.52502 19.32V9.23C3.52478 8.52853 3.79856 7.85472 4.28799 7.35221C4.77742 6.8497 5.44379 6.55825 6.14502 6.54ZM16.865 9.23V15.97H18.805C18.96 15.9687 19.1132 15.9369 19.2558 15.8764C19.3985 15.8159 19.5278 15.7278 19.6365 15.6173C19.7451 15.5068 19.8309 15.376 19.889 15.2323C19.9471 15.0887 19.9763 14.935 19.975 14.78V4.69C19.9763 4.53504 19.9471 4.38133 19.889 4.23767C19.8309 4.094 19.7451 3.96318 19.6365 3.85267C19.5278 3.74217 19.3985 3.65415 19.2558 3.59364C19.1132 3.53313 18.96 3.50131 18.805 3.5H10.755C10.6001 3.50131 10.4469 3.53313 10.3042 3.59364C10.1615 3.65415 10.0322 3.74217 9.92355 3.85267C9.8149 3.96318 9.72909 4.094 9.671 4.23767C9.61292 4.38133 9.5837 4.53504 9.58502 4.69V6.54H14.195C14.905 6.54528 15.5841 6.83102 16.0842 7.33492C16.5844 7.83882 16.865 8.52002 16.865 9.23Z" fill="#4E5ADD" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}



export default InvitePage;
