import React, { useEffect, useState } from 'react';
import profileImage from "./media/profile.png";
import coinImage from "./media/coin.png";
import bronzeMedalImage from './media/bronze.png';
import '../components/css/home.css';
interface HomePageProps {

}

const HomePage: React.FC<HomePageProps> = () => {
    return (
        <>

            <div className="box1">
                <div className="userData">
                    <img src={profileImage} alt="" className="profile" />
                    <div className="user-data">
                        <p className="user-name">Emily Middleton</p>
                        <p className="user-id">UID: 452815486</p>
                    </div>
                </div>

                <div className="wallet">
                    <div className="plus">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect width="24" height="24" rx="10" fill="black" />
                            <path d="M18 10H14V6C14 5.46957 13.7893 4.96086 13.4142 4.58579C13.0391 4.21071 12.5304 4 12 4C11.4696 4 10.9609 4.21071 10.5858 4.58579C10.2107 4.96086 10 5.46957 10 6L10.071 10H6C5.46957 10 4.96086 10.2107 4.58579 10.5858C4.21071 10.9609 4 11.4696 4 12C4 12.5304 4.21071 13.0391 4.58579 13.4142C4.96086 13.7893 5.46957 14 6 14L10.071 13.929L10 18C10 18.5304 10.2107 19.0391 10.5858 19.4142C10.9609 19.7893 11.4696 20 12 20C12.5304 20 13.0391 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18V13.929L18 14C18.5304 14 19.0391 13.7893 19.4142 13.4142C19.7893 13.0391 20 12.5304 20 12C20 11.4696 19.7893 10.9609 19.4142 10.5858C19.0391 10.2107 18.5304 10 18 10Z" fill="#F7F7F7" />
                        </svg>
                    </div>
                    <button className="wallet"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <path d="M24.5 21V22.1667C24.5 22.7855 24.2542 23.379 23.8166 23.8166C23.379 24.2542 22.7855 24.5 22.1667 24.5H5.83333C5.21449 24.5 4.621 24.2542 4.18342 23.8166C3.74583 23.379 3.5 22.7855 3.5 22.1667V5.83333C3.5 5.21449 3.74583 4.621 4.18342 4.18342C4.621 3.74583 5.21449 3.5 5.83333 3.5H22.1667C22.7855 3.5 23.379 3.74583 23.8166 4.18342C24.2542 4.621 24.5 5.21449 24.5 5.83333V7H14C13.3812 7 12.7877 7.24583 12.3501 7.68342C11.9125 8.121 11.6667 8.71449 11.6667 9.33333V18.6667C11.6667 19.2855 11.9125 19.879 12.3501 20.3166C12.7877 20.7542 13.3812 21 14 21M14 18.6667H25.6667V9.33333H14M18.6667 15.75C18.2025 15.75 17.7574 15.5656 17.4292 15.2374C17.101 14.9092 16.9167 14.4641 16.9167 14C16.9167 13.5359 17.101 13.0908 17.4292 12.7626C17.7574 12.4344 18.2025 12.25 18.6667 12.25C19.1308 12.25 19.5759 12.4344 19.9041 12.7626C20.2323 13.0908 20.4167 13.5359 20.4167 14C20.4167 14.4641 20.2323 14.9092 19.9041 15.2374C19.5759 15.5656 19.1308 15.75 18.6667 15.75Z" fill="#F7F7F7" />
                    </svg></button>
                </div>
            </div>

            <div className="box2-mask">

            </div>
            <div className="box2">
                <div className="sec1">
                    <div className="balance">
                        <p className="t1">Balance</p>
                        <div className="coins">
                            <img src={coinImage} alt="" />
                            <p className="t2">35.50</p>
                        </div>
                    </div>

                    <div className="league">
                        <div className="levels">
                            <div className="league-logo">
                                <img src={bronzeMedalImage} alt="" className="logo" />
                            </div>
                            <div className="league-info">
                                <div className="league-info-charter">
                                    <p className="t1">
                                        Bronze
                                    </p>
                                    <p className="t2">
                                        <span>1</span>/7
                                    </p>
                                </div>

                                <div className="progress">

                                </div>
                            </div>

                        </div>
                        <div className="crest">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <g clip-path="url(#clip0_2363_64)">
                                    <path d="M2.56151 2.5H5.56151C6.11351 2.5 6.50001 2.448 6.50001 3V2C6.50001 1.448 6.11351 1 5.56151 1H1.56151C0.561508 1 -0.229992 2.0625 0.0615077 3.5C0.0615077 3.5 0.957008 8.1875 1.02001 8.479C1.18651 9.25 2.08201 10 3.04051 10H6.51951C7.07201 10 6.50001 9.052 6.50001 8.5V7.5C6.50001 8.052 6.11351 8.5 5.56151 8.5H3.56151C3.00951 8.5 2.64501 7.979 2.56151 7.5C2.47801 7.021 1.76951 3.8335 1.76951 3.8335C1.60301 2.875 2.00901 2.5 2.56151 2.5ZM15.4675 2.5H12.4675C11.9155 2.5 11 2.448 11 3V2C11 1.448 11.9155 1 12.4675 1H16.4675C17.4675 1 18.2595 2.0625 17.9675 3.5C17.9675 3.5 17.151 8.2095 17.082 8.5C16.905 9.25 16.061 10 15.082 10H11.509C10.957 10 11 9.052 11 8.5V7.5C11 8.052 11.9155 8.5 12.4675 8.5H14.4675C15.0195 8.5 15.3845 7.979 15.4675 7.5C15.5505 7.021 16.2595 3.8335 16.2595 3.8335C16.4255 2.875 16.02 2.5 15.4675 2.5ZM10.416 11C10.416 7.521 9.06151 11 9.06151 11C9.06151 11 7.56151 7.521 7.56151 11C7.56151 14.479 5.91601 16 5.91601 16H12.062C12.0615 16 10.416 14.479 10.416 11Z" fill="#FFAC33" />
                                    <path d="M14.5615 3.28849C14.5615 6.67599 11.1765 12.3845 9.06149 12.3845C6.94649 12.3845 3.56149 6.67599 3.56149 3.28849C3.56149 0.690991 4.06149 0.128991 5.06149 0.128991C5.74849 0.128991 8.07399 0.115491 9.06149 0.115491L12.5615 0.114991C14.02 0.114491 14.5615 0.456991 14.5615 3.28849Z" fill="#FFCC4D" />
                                    <path d="M13.5 16.5C13.5 17.052 13.6135 17.5 13.0615 17.5H5.0615C4.509 17.5 4.5 17.052 4.5 16.5V16C4.5 15.448 5.082 15 5.603 15H12.5615C13.0825 15 13.5 15.448 13.5 16V16.5Z" fill="#C1694F" />
                                    <path d="M14.5 17.3125C14.5 17.6925 14.5825 18 13.874 18H4.249C3.603 18 3.5 17.6925 3.5 17.3125V17.1875C3.5 16.8075 3.869 16.5 4.249 16.5H13.874C14.2535 16.5 14.5 16.8075 14.5 17.1875V17.3125Z" fill="#C1694F" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2363_64">
                                        <rect width="18" height="18" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};

export default HomePage;